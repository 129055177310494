@import url('https://fonts.googleapis.com/css2?family=Poppins');

/* Styles of React */
/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
/* Fin React */

/* Ant design */
.trigger {
  cursor: pointer;
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  transition: color 0.3s;
}
.trigger:hover {
  color: #1DA53C;
}
#components-layout-demo-custom-trigger .logo {
  background: rgba(255, 255, 255, 0.3);
  height: 32px;
  margin: 16px;
}
.site-layout .site-layout-background {
  background: #fff;
}
/* Table */
thead[class*="ant-table-thead"] th{
  background-color: var(--var-second-color);
  font-weight: bold;
  letter-spacing: 1.1px;
}
/* Menu in NavBar */
.ant-menu-item.ant-menu-item-selected{
  border-radius: 0;
  box-sizing: border-box;
  margin: 0;
  width: 100%;
}
/* SubMenu in NavBar */
.ant-menu-item.ant-menu-item-selected.ant-menu-item-only-child{
  border-radius: 0;
  box-sizing: border-box;
  margin-left: 0;
  margin-right: 0;
  width: 100%;
}

/* Fin Ant design */
/* Styles of YCS */
body{
  font-family: 'Poppins', sans-serif;
  min-height: 100vh;
}
.ycs-card{
  background-color: white;
  border-radius: 10px;
  box-shadow: rgb(0 0 0 / 10%) 0rem 0.25rem 0.375rem -0.0625rem, rgb(0 0 0 / 6%) 0rem 0.125rem 0.25rem -0.0625rem;
  box-sizing: border-box;
  margin-bottom: 15px;
  padding: 5px 20px;
  overflow: auto;
}
.App {
  padding-top: 200px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.celdaTablaLEctura{
  padding: 6px !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 767.99px) {
  .ant-layout {
  position: relative;
  overflow: hidden;
  min-width: 100vw;
}}
  
/* Fin of YCS */
